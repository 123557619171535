import request from '@/utils/request'

const developerStatisticsApi = {
    statisticsReport: '/api/v1/developerStatistics/statisticsReport'
}

export function statisticsReport (data) {
    return request({
        url: developerStatisticsApi.statisticsReport,
        method: 'post',
        data
    })
}
