<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="经纪人">
                <a-input v-model="queryParam.brokerName" allow-clear placeholder="请输入经纪人姓名" />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="状态">
                <a-select
                  show-search
                  :filter-option="filterOption"
                  option-filter-prop="children"
                  v-model="queryParam.status"
                  placeholder="请选择"
                  @change="handleSelectChange"
                  allowClear
                >
                  <a-select-option :value="item.num" v-for="(item, index) in status" :key="index">{{
                    item.value
                  }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="代理商">
                  <a-input v-model="queryParam.agentName" allow-clear placeholder="请输入代理商名称" />
                </a-form-item>
              </a-col>
              <!-- <a-col :md="8" :sm="24"></a-col>
                <a-form-item label="单据类型">
                  <a-select
                    show-search
                    :filter-option="filterOption"
                    option-filter-prop="children"
                    v-model="queryParam.naturalVisit"
                    placeholder="请选择"
                    @change="handleSelectType"
                    allowClear
                  >
                    <a-select-option :value="item.type" v-for="(item, index) in orderType" :key="index">{{
                      item.value
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col> -->
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="reload">查询</a-button>
                <a-button style="margin-left: 8px" @click="resetReload">重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-button type="primary" icon="file-word" @click="excelExport">导出</a-button>
      </div>

      <s-table
        ref="table"
        size="default"
        :rowKey="record => record.reportId"
        :columns="columns"
        :data="orderData"
        :alert="true"
        :totalCount="totalCount"
        showPagination="auto"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="naturalVisit" slot-scope="text">{{ text | visitFilter }}</span>
        <span slot="orderStatus" slot-scope="text">
          <!-- <a-badge :text="text" /> -->
          <a-badge :status="text | statusTypeFilter" :text="text | statusFilter" />
        </span>
        <span slot="description" slot-scope="text">
          <ellipsis :length="4" tooltip>{{ text }}</ellipsis>
        </span>

        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleEdit(record)">查看</a>
          </template>
        </span>
      </s-table>

      <!-- sign info -->
      <a-drawer 
        title="" 
        placement="right" 
        class="myDescription" 
        :width="drawerWidth" 
        :visible="visible" 
        @close="onClose">
        <a-spin :spinning="confirmLoading" v-if="order">
          <a-card :bordered="false">
            <a-descriptions :title="order.id ? '订单号：' + order.id : '订单号：-'"></a-descriptions>
            <template v-if="order && order.status == 1">
              <a-descriptions title="签约信息" class="sign">
                <a-descriptions-item label="客户姓名" span="3">{{
                  order.clientName ? order.clientName : '-'
                }}</a-descriptions-item>
                <a-descriptions-item label="客户手机号" span="3">{{
                  order.clientPhone ? order.clientPhone : '-'
                }}</a-descriptions-item>
                <a-descriptions-item label="经纪人" span="3">{{
                  order.brokerName ? order.brokerName : '-'
                }}</a-descriptions-item>
                <a-descriptions-item label="经纪人手机号" span="3">{{
                  order._embedded ? order._embedded.report.broker.phone : '-'
                }}</a-descriptions-item>
                <a-descriptions-item label="置业顾问" span="3">{{
                  order.consultantName ? order.consultantName : '-'
                }}</a-descriptions-item>
                <a-descriptions-item label="签约房号" span="3">{{
                  order.contractRoom ? order.contractRoom : '-'
                }}</a-descriptions-item>
                <a-descriptions-item label="房屋总价" span="3">{{
                  order.contractPrice ? order.contractPrice : '-'
                }}</a-descriptions-item>
                <a-descriptions-item label="签约时间" span="3">{{
                  order.contractTime ? order.contractTime : '-'
                }}</a-descriptions-item>
                <a-descriptions-item label="合同照片" span="3" v-if="contractPhotos">
                  <a-card
                    hoverable
                    style="width: 120px"
                    v-for="item in contractPhotos"
                    :key="item.id"
                    class="inlineBlock"
                  >
                    <!-- <img
                      slot="cover"
                      alt="example"
                      :src="item.path"
                    /> -->
                    <media :file="item" />
                  </a-card>
                </a-descriptions-item>
              </a-descriptions>
              <a-divider style="margin-bottom: 32px" />
            </template>
            <a-descriptions title="认购信息" class="sign">
              <template>
                <a-descriptions-item label="代理商" span="3">{{ report.agentName | textFilter }}</a-descriptions-item>
                <a-descriptions-item label="客户姓名" span="3">{{
                  order.clientName ? order.clientName : '-'
                }}</a-descriptions-item>
                <a-descriptions-item label="客户手机号" span="3">{{
                  order.clientPhone ? order.clientPhone : '-'
                }}</a-descriptions-item>
                <a-descriptions-item label="经纪人" span="3">{{
                  order.brokerName ? order.brokerName : '-'
                }}</a-descriptions-item>
                <a-descriptions-item label="经纪人手机号" span="3">{{
                  order._embedded ? order._embedded.report.broker.phone : '-'
                }}</a-descriptions-item>
                <a-descriptions-item label="置业顾问" span="3">{{
                  order.consultantName ? order.consultantName : '-'
                }}</a-descriptions-item>
              </template>
              <a-descriptions-item label="认购房号" span="3">{{ order.room ? order.room : '-' }}</a-descriptions-item>
              <a-descriptions-item label="房屋总价" span="3">{{ order.price ? order.price : '-' }}</a-descriptions-item>
              <a-descriptions-item label="认购时间" span="3">{{
                order.createTime ? order.createTime : '-'
              }}</a-descriptions-item>
              <a-descriptions-item label="预计签约时间" span="3">{{
                order.signDate ? order.signDate : '-'
              }}</a-descriptions-item>
              <a-descriptions-item label="备注" span="3">{{ order.remark ? order.remark : '-' }}</a-descriptions-item>
              <a-descriptions-item label="认购单照片" span="3" v-if="photos">
                <a-card hoverable style="width: 120px" v-for="item in photos" :key="item.id" class="inlineBlock">
                  <media :file="item" />
                </a-card>
              </a-descriptions-item>
            </a-descriptions>
            <a-divider style="margin-bottom: 32px" />
            <a-descriptions title="到访信息" class="sign">
              <a-descriptions-item label="驻场人员" span="3">{{
                order._embedded ? order._embedded.resident.name : '-'
              }}</a-descriptions-item>
              <a-descriptions-item label="置业顾问" span="3">{{
                order._embedded ? order._embedded.report.consultant.name : '-'
              }}</a-descriptions-item>
              <a-descriptions-item label="到访时间" span="3">{{
                order._embedded ? order._embedded.report.acceptTime : '-'
              }}</a-descriptions-item>
              <a-descriptions-item
                label="到访照片"
                span="3"
                v-if="(order._embedded && order._embedded.report.evidence) || (order.report && order.report.evidence)"
              >
                <a-card
                  hoverable
                  style="width: 120px"
                  v-for="item in order._embedded ? order._embedded.report.evidence : order.report.evidence"
                  :key="item.id"
                  class="inlineBlock"
                >
                  <!-- <img
                    slot="cover"
                    alt="example"
                    :src="$store.state.oss.host + item.path"
                  /> -->
                  <media :file="item" />
                </a-card>
              </a-descriptions-item>
            </a-descriptions>
            <a-descriptions title="日志" class="log"> </a-descriptions>
          </a-card>
        </a-spin>
      </a-drawer>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis, Media } from '@/components'
import StepByStepModal from './modules/StepByStepModal'
import CreateForm from './modules/CreateSign'
import { contractPhotos, getPhotos, findByReportId } from '@/api/orders'
import { statisticsReport } from '@/api/developerStatistics'
const columns = [
  {
    title: '客户',
    dataIndex: 'clientName'
  },
  {
    title: '经纪人',
    dataIndex: 'brokerName'
  },
  {
    title: '置业顾问',
    dataIndex: 'consultantName'
  },
  {
    title: '代理商',
    dataIndex: 'agentName'
  },
  {
    title: '时间',
    dataIndex: 'time'
  },
  // {
  //     title: '到访类型',
  //     dataIndex: 'naturalVisit',
  //     scopedSlots: { customRender: 'naturalVisit' }
  // },
  {
    title: '状态',
    dataIndex: 'orderStatus',
    scopedSlots: { customRender: 'orderStatus' }
  },
  {
    title: '操作',
    dataIndex: 'action',
    fixed: 'right',
    scopedSlots: { customRender: 'action' }
  }
]

const statusMap = {
  0: {
    status: 'default',
    text: '已认购'
  },
  1: {
    status: 'processing',
    text: '已签约'
  }
}
export default {
  name: 'TableList',
  components: {
    STable,
    Ellipsis,
    CreateForm,
    StepByStepModal,
    Media
  },
  data() {
    this.columns = columns
    return {
      ids: '',
      visible: false,
      confirmLoading: false,
      mdl: null,
      advanced: false,
      queryParam: {},
      report: null,
      order: null,
      drawer: [],
      contractPhotos: [],
      photos: [],
      value: undefined,
      totalCount: 0,
      orderData: parameter => {
        const param = {
          developerId: this.userId,
          houseId: this.houseId
        }
        const params = this.paramFormat(parameter, param)
        Object.assign(params, this.queryParam)
        return statisticsReport(params).then(res => {
          const result = this.resFormat(res)
          result.data.forEach(item => {
            item.orderId ? (item.time = `签约时间: ${item.contractTime}`) : (item.time = `认购时间: ${item.orderTime}`)
          })
          this.totalCount = result.totalCount
          return result
        })
      },
      selectedRowKeys: [],
      selectedRows: [],
      status: [
        {
          num: 10,
          value: '已认购'
        },
        {
          num: 1,
          value: '已签约'
        }
      ],
      orderType: [
        {
          type: 1,
          value: '经纪人报备'
        },
        {
          type: 0,
          value: '自然到访'
        }
      ]
    }
  },
  filters: {
    statusFilter(type) {
      return statusMap[type].text
    },
    statusTypeFilter(type) {
      return statusMap[type].status
    },
    visitFilter(type) {
      console.log(type, 'mmmm');
      if (type === 0) {
        return '自然到访'
      } else {
        return '经纪人报备'
      }
    }
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange
      }
    },
    houseId() {
      return this.$store.state.house.houseId
    },
    developerId() {
      return this.$store.state.user.info.id
    },
    userId() {
      return this.$store.state.user.info.id
    },
    serial() {
      return this.$store.state.env.serial
    }
  },
  methods: {
    reload() {
      this.$refs.table.refresh()
    },
    resetReload() {
      this.queryParam = {}
      this.reload()
    },
    handleAdd() {
      this.$notification['success']({
        message: '导出成功',
        description: '成功导出Exel.'
      })
    },
    handleEdit(record) {
      this.confirmLoading = true
      findByReportId(record.reportId).then(res => {
        this.order = res
        this.report = res._embedded.report
        contractPhotos(res.id).then(res => {
          this.contractPhotos = res._embedded.medias
        })
        getPhotos(res.id).then(res => {
          this.photos = res._embedded.medias
          this.confirmLoading = false
        })
      })
      this.visible = true
    },
    onClose() {
      this.visible = false
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    handleSelectChange(value) {
      this.queryParam.status = value
    },
    handleSelectType(value) {
      this.queryParam.naturalVisit = value
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    excelExport() {
      const href = window.origin + '/api/v1/developerStatistics/export'
      const params = {
        developerId: this.developerId,
        houseId: this.houseId,
        ...this.queryParam
      }
      console.log(this.requestFormat(href, params));
      window.location.href = this.requestFormat(href, params)
    }
  }
}
</script>
<style lang="less" scoped>
.log {
  /deep/ .ant-descriptions-item-content {
    span:first-child {
      font-weight: 700;
    }
    span:last-child {
      font-weight: 700;
      margin-left: 50px;
    }
  }
  /deep/ .ant-descriptions-item-label {
    font-weight: 700;
  }
}
.sign {
  /deep/ .ant-card {
    width: 120px;
    display: inline-block;
    vertical-align: text-top;
    margin-right: 20px;
    margin-top: 20px;
  }
}
</style>
