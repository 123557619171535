<template>
  <a-drawer
    title="流水号：952788888888"
    placement="right"
    width="600px"
    :visible="visible"
    @close="() => { $emit('cancel')}"
  >
    <a-card :bordered="false">
      <a-descriptions title="报备信息">
        <a-descriptions-item label="客户姓名" span="3">王丽丽</a-descriptions-item>
        <a-descriptions-item label="客户手机号" span="3">19885555999</a-descriptions-item>
        <a-descriptions-item label="经纪人" span="3">张经济</a-descriptions-item>
        <a-descriptions-item label="经纪人手机号" span="3">19554444777</a-descriptions-item>
        <a-descriptions-item label="预定到访时间" span="3">当天</a-descriptions-item>
        <a-descriptions-item label="报备时间" span="3">2020-08-20 13:32</a-descriptions-item>
      </a-descriptions>
      <a-divider style="margin-bottom: 32px" />
      <a-descriptions title="到访信息" class="evidence">
        <a-descriptions-item label="置业顾问" span="3">李顾问</a-descriptions-item>
        <a-descriptions-item label="到访时间" span="3">2020-08-20 15:32</a-descriptions-item>
        <a-descriptions-item label="到访证据" span="3">
          <a-card hoverable style="width: 120px" v-for="item in evidence" :key="item.id">
            <img slot="cover" alt="example" :src="item.url" />
          </a-card>
        </a-descriptions-item>
      </a-descriptions>
      <a-divider style="margin-bottom: 32px" />
      <a-descriptions title="日志" class="log">
        <a-descriptions-item label="张经纪" span="3"><span>上传证据链</span><span>2020-20-13 13:33:25</span></a-descriptions-item
        >
        <a-descriptions-item label="王驻场" span="3"><span>到访扫码</span><span>2020-20-13 13:33:25</span></a-descriptions-item
        >
        <a-descriptions-item label="王驻场" span="3"><span>审核通过</span><span>2020-20-13 13:33:25</span></a-descriptions-item
        >
        <a-descriptions-item label="张经纪" span="3"><span>发起报备</span><span>2020-20-13 13:33:25</span></a-descriptions-item
        >
      </a-descriptions>
    </a-card>
  </a-drawer>
</template>

<script>
import pick from 'lodash.pick'

// 表单字段
const fields = ['description', 'id']

export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data() {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      form: this.$form.createForm(this),
      evidence: [
        {
          url: 'https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png'
        },
        {
          url: 'https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png'
        },
        {
          url: 'https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png'
        },
        {
          url: 'https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png'
        },
        {
          url: 'https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png'
        },
        {
          url: 'https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png'
        },
        {
          url: 'https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png'
        },
        {
          url: 'https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png'
        },
        {
          url: 'https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png'
        }
      ]
    }
  },
  created() {
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))

    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  methods: {
    // report info
  }
}
</script>
